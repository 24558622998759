import React from 'react'
import styles from '../styles/components/AppSection.module.css'

function AppSection({ children }: { children: JSX.Element[] | JSX.Element | any }) {
  return (
    <div className={styles.container}>
      <div>{children}</div>
    </div>
  )
}

export default AppSection
