import { RippleButton } from '@uniqs-labs/ui-react-lib'
import { useRouter } from 'next/router'
import React from 'react'
import AppSection from '../components/AppSection'

export default function Custom500() {
  const router = useRouter()
  return (
    <div style={{ height: '30rem', width: '100vw', margin: '0 auto', display: 'grid' }}>
      <AppSection>
        <h5>We are sorry, this page does not exist.</h5>
        <RippleButton primary size="small" label="Go back" onClick={() => router.back()} />
        <RippleButton size="small" label="Reload" onClick={() => router.reload()} />
      </AppSection>
    </div>
  )
}
